import React from "react"
import Layout from "./../components/layouts/Default"

import Header from "./../components/common/Header"
import { graphql } from "gatsby"
import Card from "./../components/common/Card"
import Reviews from "./../components/Reviews"

export default function Page({ data, location }) {
  return (
    <Layout
      title="Finanzierungen bei Dierda Finanzdienstleistungen"
      description="Wenn Deine eigenen finanziellen Mittel nicht für eine Anschaffung reichen, hilft Dir eine Finanzierung, den monetären Engpass zu überbrücken."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        link="#angebote"
        linktext="Zum Angebot"
        image={data.header}
      >
        <h1 className="mt-1 text-2xl font-extrabold leading-relaxed text-gray-900 sm:text-3xl lg:text-4xl ">
          <span className="text-blue-500">Finanzierungen</span>
        </h1>

        <p className="prose prose-lg text-gray-700 ">
          Wenn Deine eigenen finanziellen Mittel nicht für eine Anschaffung
          reichen, hilft Dir eine Finanzierung, den monetären Engpass zu
          überbrücken. Wir finden für Dich das passende Finanz-Produkt, welches
          Dein Vorhaben kostengünstig in die Tat umsetzen lässt.{" "}
        </p>
      </Header>
      <div className="container mx-auto my-6">
        <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center" id="angebote">
              <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Unsere Finanzierungsangebote
              </h2>
              <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-700 sm:mt-4">
                Nachfolgend findest Du eine Auswahl unserer
                Finanzierungs-Leistungen.
              </p>
            </div>
            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
              <Card
                image={data.kredit}
                title="Privatkredit"
                link="/finanzierungen/privatkredit/"
                subtitle="Finanzierungen"
                sublink="/finanzierungen/"
                text="Wir finden Deinen günstigsten Privatkredit."
              />
              <Card
                image={data.baufi}
                title="Baufinanzierung"
                link="/finanzierungen/baufinanzierung/"
                subtitle="Finanzierungen"
                sublink="/finanzierungen/"
                text="Niedrige Zinsen bei der Immobilienfinanzierung: Wir vergleichen und beraten Dich unabhängig."
              />
            </div>
          </div>
        </div>
        <Reviews />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Finanzierungen {

    site {
      siteMetadata {
        siteUrl
      }
    }
    
    header: file(relativePath: {eq: "headers/dierda_finanzierungen_header.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: {
        eq: "headers/dierda_finanzierungen_header.jpg"
      }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }



    kredit: file(
      relativePath: { eq: "headers/dierda_privatkredit_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData       
      }
    }

    baufi: file(
      relativePath: { eq: "headers/dierda_baufinanzierung_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData       
      }
    }
  }
`
